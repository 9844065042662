.select-wrapper {
  position: relative;
  width: fit-content; /* Adjust width as needed */
}

.icon-wrapper {
  position: absolute;
  top: 50%;
  // left: 50%;
  // left: 10px;
  // transform: translateY(-50%), translateX(50%);
  font-size: 22px;
  transform: translate(80%, -55%);
  pointer-events: none; /* Allows click to pass through */
  /* Style your icon wrapper as needed */
  z-index: 1000;
}

select:focus {
  outline: none;
  /* Consider adding alternative focus styles here for accessibility */
}

/* Optional: Style the select to accommodate the icon */
select {
  -webkit-appearance: none; /* Removes default styling for WebKit browsers */
  -moz-appearance: none; /* Removes default styling for Mozilla browsers */
  appearance: none; /* Removes default browser dropdown arrow */
  padding-right: 30px; /* Adjust padding to not overlap with the icon */
  /* Further styling */
}
