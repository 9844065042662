.auth-container {
  display: flex;
  justify-content: center;
  padding: 40px;
  form {
    width: 40%;
  }
  .icons {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 50px;
    svg:hover {
      color: gray;
      cursor: pointer;
    }
  }
}
