@font-face {
  font-family: FiraGOBold;
  src: url(./assets/fonts/FiraGO/FiraGO-Bold.ttf) format("embedded-opentype"),
    url(./assets/fonts/FiraGO/FiraGO-Bold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: FiraGOSemiBold;
  src: url(./assets/fonts/FiraGO/FiraGO-SemiBold.ttf?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/FiraGO/FiraGO-SemiBold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

body.ka h1,
h2,
h3 {
  font-family: FiraGOBold;
}

body.ka p,
h4,
h5,
h6 {
  font-family: FiraGOSemiBold;
}
img {
  -webkit-user-select: none;
  /* Disable selection on Chrome, Safari, and Opera */
  -moz-user-select: none;
  /* Disable selection on Firefox */
  -ms-user-select: none;
  /* Disable selection on Edge */
  user-select: none;
  /* Disable selection on other browsers */
}

.scroll-link {
  opacity: 0.5;
  cursor: pointer;
}

.scroll-link:hover {
  opacity: 0.7;
}

.scroll-link-active {
  opacity: 1;
}

.see-more {
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
  color: rgb(230, 228, 228);
  font-weight: bold;
}

.whitepaper-section .whitepaper__sidebar ul {
  flex-direction: column;
}

.page-404 {
  height: 90vh;
}

label {
  margin-top: 8px;
}

.top-margin {
  margin-top: 20px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.loading {
  margin-top: -73px;
}

.navigation__left-socials a img {
  transition: 0.2s ease;
}
.navigation__left-socials a:hover {
  background-color: #e7e5ec;
}
.navigation__left-socials a:hover img {
  transform: scale(1.2);
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 24px;
  border-radius: 12px;
  border: 1px solid rgba(214, 66, 66, 0.3);
  background-color: rgba(214, 66, 66, 0.1);
  p {
    color: #d64242;
    margin: 0;
  }
}

p {
  margin: 0;
}
@media only screen and (max-width: 1200px) {
  .loading {
    margin-top: -65px;
  }
  .navigation__left ul li a img {
    display: block;
  }
}

@media (max-width: 350px) {
  .hide-on-small-screens {
    display: none;
  }
}

@media (max-width: 500px) {
  .navigation__right {
    a {
      display: none;
    }
  }
}
