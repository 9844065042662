@import url("https://fonts.googleapis.com/css?family=Karla:400,700");
@import url(../vendors/css/bootstrap.min.css);
@font-face {
  font-family: poppinsBold;
  src: url(../fonts/Poppins-Bold.ttf?#iefix) format("embedded-opentype"),
    url(../fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: poppinsSemiBold;
  src: url(../fonts/Poppins-SemiBold.ttf?#iefix) format("embedded-opentype"),
    url(../fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: latoSemiBold;
  src: url(../fonts/Lato-Bold.ttf?#iefix) format("embedded-opentype"),
    url(../fonts/Lato-Bold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: latoRegular;
  src: url(../fonts/Lato-Regular.ttf?#iefix) format("embedded-opentype"),
    url(../fonts/Lato-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: NatoSansGeorgianBold;
  src: url(../fonts/NotoSansGeorgian-Bold.ttf?#iefix)
      format("embedded-opentype"),
    url(../fonts/NotoSansGeorgian-Bold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: NatoSansGeorgianSemiBold;
  src: url(../fonts/NotoSansGeorgian-SemiBold.ttf?#iefix)
      format("embedded-opentype"),
    url(../fonts/NotoSansGeorgian-SemiBold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

.poppinsBold {
  font-family: poppinsBold;
}
.poppinsSemiBold {
  font-family: poppinsSemiBold;
}
.latoSemiBold {
  font-family: latoSemiBold;
}
.latoRegular {
  font-family: latoRegular;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1352px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    max-width: 1000px;
  }
}
.container {
  padding-left: 24px;
  padding-right: 24px;
}
body {
  color: #fff;
}
* {
  font-family: latoRegular;
}
.h1,
h1 {
  font-size: 4rem;
  font-family: poppinsBold;
  line-height: 113%;
}
@media (max-width: 1200px) {
  .h1,
  h1 {
    font-size: calc(1.525rem + 3.3vw);
  }
}
.h2,
.headline-section h1,
.waitlist-section__headline p,
h2 {
  font-size: 4rem;
  font-size: 3rem;
  font-family: poppinsBold;
  line-height: 109%;
}
@media (max-width: 1200px) {
  .h2,
  .headline-section h1,
  .waitlist-section__headline p,
  h2 {
    font-size: calc(1.525rem + 3.3vw);
    font-size: calc(1.425rem + 2.1vw);
  }
}
.h3,
.page-404 p,
h3 {
  font-size: 4rem;
  font-size: 2.5rem;
  font-family: poppinsBold;
  line-height: 120%;
}
@media (max-width: 1200px) {
  .h3,
  .page-404 p,
  h3 {
    font-size: calc(1.525rem + 3.3vw);
    font-size: calc(1.375rem + 1.5vw);
  }
}
.h4,
h4 {
  font-size: 4rem;
  font-size: 1.5rem;
  font-family: poppinsSemiBold;
  line-height: 118%;
}
@media (max-width: 1200px) {
  .h4,
  h4 {
    font-size: calc(1.525rem + 3.3vw);
    font-size: calc(1.275rem + 0.3vw);
  }
}
.h5,
h5 {
  font-size: 1rem;
  font-size: 1.25rem;
  line-height: 120%;
}
.h5,
.h6,
h5,
h6 {
  font-family: poppinsSemiBold;
}
.h6,
h6 {
  font-size: 1rem;
  line-height: 125%;
}
.headline p.subtitle span,
.subtitle {
  font-size: 1rem;
  font-family: latoSemiBold;
  line-height: 125%;
  text-transform: uppercase;
  letter-spacing: 0.2061rem;
}
.primaryText,
.rewards-section ul li span,
p {
  font-size: 1rem;
  font-size: 1.125rem;
  font-family: latoRegular;
  line-height: 138%;
}
.contact-section__sent-additional ul li a,
.cookies__body p,
.cookies__body p a,
.d-input__container .d-input__label,
.faq-section__footer p,
.news-card__info p,
.secondaryText,
.team-section .team-member__info p {
  font-size: 1rem;
  font-family: latoRegular;
  line-height: 150%;
}
.d-input__footer p,
.faq-section__item-cta a,
.footer__list p,
.footer__list ul,
.news-card .date span,
.smallText {
  font-size: 1rem;
  font-size: 0.875rem;
  font-family: latoRegular;
  line-height: 144%;
}
.tinyText {
  font-size: 1rem;
  font-size: 0.75rem;
  font-family: latoRegular;
  line-height: 134%;
}
.bt,
.button-16-bold,
.faq-section__footer p span,
.news-card__info p span,
.news-section__slider-controller .next,
.news-section__slider-controller .prev,
.terms-modal__cls {
  font-size: 1rem;
  font-family: latoSemiBold;
  line-height: 150%;
}
.button-16-regular,
.navigation__left ul li a {
  font-size: 1rem;
  font-family: latoRegular;
  line-height: 150%;
}
.bt-lg,
.button-18-bold,
.footer__list h6,
.news-card__info h6,
.s-form__button {
  font-size: 1rem;
  font-size: 1.125rem;
  font-family: latoSemiBold;
  line-height: 156%;
}
.button-18-regular,
.d-input__container .d-input__field,
.s-form__input {
  font-size: 1rem;
  font-size: 1.125rem;
  font-family: latoRegular;
  line-height: 156%;
}
.hamburger {
  width: 28px;
  height: 18px;
  cursor: pointer;
}
.hamburger .menu,
.hamburger .menu:after,
.hamburger .menu:before {
  width: 28px;
  height: 2px;
  border-radius: 2px;
}
.hamburger .menu.animate {
  background: hsla(0, 0%, 100%, 0);
}
.hamburger .menu {
  position: relative;
  transform: translateY(8px);
  background: #fff;
  transition: all 0ms 0.2s;
}
.hamburger .menu.active {
  background: hsla(0, 0%, 100%, 0);
}
.hamburger .menu:before {
  bottom: 8px;
  transition: bottom 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.2s,
    transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger .menu:after,
.hamburger .menu:before {
  content: "";
  position: absolute;
  left: 0;
  background: #fff;
}
.hamburger .menu:after {
  top: 8px;
  transition: top 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.2s,
    transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger .menu.active:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 0.2s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
}
.hamburger .menu.active:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 0.2s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
}
@keyframes a {
  0% {
    border-color: #3b86f6;
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    border-color: #ee2c72;
  }
  50% {
    border-color: #f7be2b;
    transform: translate(-50%, -50%) scale(1.2);
  }
  to {
    border-color: #9fabc3;
    transform: translate(-50%, -50%) scale(1);
  }
}
.ico {
  width: 24px;
  height: 24px;
}
.bt,
.news-section__slider-controller .next,
.news-section__slider-controller .prev {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px 20px;
  color: #fff;
  background-image: linear-gradient(135deg, #016eda, #d51375, #d51375, #016eda);
  border-radius: 16px;
  moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-size: 300% 100%;
  border: none;
  box-shadow: inset 0 6px 10px -5px rgba(51, 88, 193, 0.8);
}
.bt.bt-black,
.news-section__slider-controller .bt-black.next,
.news-section__slider-controller .bt-black.prev {
  background: #1b1c2d;
  color: #e7e5ec;
  border: 1px solid #242638;
}
.bt.bt-black:hover,
.news-section__slider-controller .bt-black.next:hover,
.news-section__slider-controller .bt-black.prev:hover {
  background-color: #242638;
}
.bt.bt-black:after,
.news-section__slider-controller .bt-black.next:after,
.news-section__slider-controller .bt-black.prev:after {
  background-color: #1b1c2d;
  background-image: unset;
}
.bt .ico,
.news-section__slider-controller .next .ico,
.news-section__slider-controller .prev .ico {
  width: 16px;
  height: 16px;
}
.bt .ico.left,
.news-section__slider-controller .next .ico.left,
.news-section__slider-controller .prev .ico.left {
  margin-right: 4px;
  margin-left: -8px;
}
.bt .ico.right,
.news-section__slider-controller .next .ico.right,
.news-section__slider-controller .prev .ico.right {
  margin-left: 4px;
  margin-right: -8px;
}
.bt:hover,
.news-section__slider-controller .next:hover,
.news-section__slider-controller .prev:hover {
  background-position: 100% 0;
  color: #fff;
  box-shadow: inset 0 10px 10px -5px rgba(51, 88, 193, 0.8);
}
.bt-lg {
  padding: 12px 32px;
  border-radius: 20px;
}
.bt-lg .ico {
  width: 24px;
  height: 24px;
}
.bt-lg .ico.left {
  margin-right: 8px;
  margin-left: -16px;
}
.bt-lg .ico.right {
  margin-left: 8px;
  margin-right: -16px;
}
.bt-secondary,
.news-section__slider-controller .next,
.news-section__slider-controller .prev {
  background: #110f24;
}
.bt-secondary.bt-black,
.news-section__slider-controller .bt-black.next,
.news-section__slider-controller .bt-black.prev {
  background: #00010d;
  color: #b0abbb;
}
.bt-secondary.bt-black:hover,
.news-section__slider-controller .bt-black.next:hover,
.news-section__slider-controller .bt-black.prev:hover {
  background-color: #0e0f1a;
}
.bt-secondary.bt-black:after,
.news-section__slider-controller .bt-black.next:after,
.news-section__slider-controller .bt-black.prev:after {
  background-color: #1b1c2d;
  background-image: unset;
}
.bt-secondary:after,
.news-section__slider-controller .next:after,
.news-section__slider-controller .prev:after {
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  background-image: linear-gradient(135deg, #016eda, #d51375, #d51375, #016eda);
  background-size: 300% 100%;
  moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.bt-secondary.bt-lg:after,
.news-section__slider-controller .bt-lg.next:after,
.news-section__slider-controller .bt-lg.prev:after {
  border-radius: 21px;
}
.bt-secondary:hover,
.news-section__slider-controller .next:hover,
.news-section__slider-controller .prev:hover {
  background: #0c0b19;
}
.bt-secondary:hover:after,
.news-section__slider-controller .next:hover:after,
.news-section__slider-controller .prev:hover:after {
  background-position: 100% 0;
}
.bt-extra {
  background: #110f24;
  color: #b0abbb;
}
.bt-extra:hover {
  color: #fff;
  background: #0c0b19;
}
.bt-text {
  background: none;
  color: #b0abbb;
}
.bt-text:hover {
  color: #fff;
  background-color: #110f24;
}
.bt-loading {
  overflow: hidden;
}
.bt-loading,
.bt-loading:hover {
  color: transparent;
}
.bt-loading:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(14, 15, 26, 0.5);
}
.bt-loading:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  background: transparent;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  animation: a 1.5s linear infinite;
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0 1000px #0b0b14 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.s-form {
  padding: 24px 0;
}
.s-form__wrapper {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  max-width: 376px;
  border-radius: 32px;
}
.s-form__wrapper:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: -1;
  background-image: linear-gradient(135deg, #016eda, #d51375, #d51375, #016eda);
  background-size: 300% 100%;
  border-radius: 32px;
}
.s-form__input {
  padding: 11px 154px 11px 24px;
  border-radius: 32px;
  background-color: #1b1c2d;
  color: #fff;
  width: 100%;
  border: none;
}
@media only screen and (max-width: 576px) {
  .s-form__input {
    padding: 11px 142px 11px 16px;
  }
}
.s-form__input:focus {
  outline: none;
  box-shadow: 0 4px 16px rgba(240, 43, 90, 0.2);
}
.s-form__button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  border: none;
  outline: none;
  border-radius: 32px;
  padding: 0 32px;
  background-image: linear-gradient(90deg, #0e0f1a, #242638);
  color: #fff;
  transition: 0.2s ease;
}
@media only screen and (max-width: 576px) {
  .s-form__button {
    padding: 0 24px;
  }
}
.s-form__button:focus,
.s-form__button:hover {
  background-image: linear-gradient(135deg, #016eda, #d51375, #d51375, #016eda);
  background-size: 300% 100%;
}
.s-form__button:focus {
  outline: none;
}
.s-form__message {
  left: 0;
  position: absolute;
  top: 100%;
  margin: 0;
  width: 100%;
  text-align: center;
  padding-top: 6px;
}
.s-form .s-form__message.error {
  color: #d64242;
}
.s-form .s-form__message.success {
  color: #53b769;
}
.s-form .s-form__message.info {
  color: #838385;
}
.d-input {
  padding: 16px 0;
}
.d-input__wrapper {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 16px;
  width: 100%;
  margin-bottom: 24px;
}
@media only screen and (max-width: 576px) {
  .d-input__wrapper {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.d-input .d-checkbox__wrapper .d-checkbox__container {
  width: 100%;
}
.d-input .d-checkbox__wrapper .d-checkbox__label {
  cursor: pointer;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}
.d-input .d-checkbox__wrapper .d-checkbox__label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #242638;
  border-radius: 6px;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  transition: 0.2s ease;
}
.d-input .d-checkbox__wrapper .d-checkbox__input {
  padding: 0;
  height: auto;
  width: auto;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.d-input
  .d-checkbox__wrapper
  .d-checkbox__input:hover
  + .d-checkbox__label:before {
  border-color: #1b1c2d;
}
.d-input
  .d-checkbox__wrapper
  .d-checkbox__input:checked
  + .d-checkbox__label:before {
  background-color: #f02b5a;
  border-color: #f02b5a;
}
.d-input
  .d-checkbox__wrapper
  .d-checkbox__input:checked
  + .d-checkbox__label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 12px;
  border: solid #e7e5ec;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.d-input__message {
  position: absolute;
}
.d-input__container {
  position: relative;
  width: 100%;
}
.d-input__container .d-input__label {
  display: block;
  margin-bottom: 6px;
  color: #e7e5ec;
}
.d-input__container .d-input__field {
  display: block;
  width: 100%;
  padding: 12px 16px;
  border-radius: 16px;
  border: 1px solid #242638;
  background-color: #0b0b14;
  transition: all 0.2s ease;
  color: #fff;
}
.d-input__container .d-input__field:focus {
  outline: none;
  border-color: #f02b5a;
  box-shadow: 0 0 0 4px hsla(260, 5%, 55%, 0.12);
}
.d-input__container .d-input__field:hover {
  box-shadow: 0 0 0 4px hsla(260, 5%, 55%, 0.12);
}
.d-input__container .d-input__field::-webkit-input-placeholder {
  opacity: 0.5;
}
.d-input__container .d-input__field::-moz-placeholder {
  opacity: 0.5;
}
.d-input__container .d-input__field::-ms-input-placeholder {
  opacity: 0.5;
}
.d-input__container .d-input__field::placeholder {
  opacity: 0.5;
}
.d-input__container.error .d-input__message {
  color: #d64242;
}
.d-input__container.error .d-input__field {
  border-color: rgba(214, 66, 66, 0.3);
}
.d-input__container.success .d-input__message {
  color: #53b769;
}
.d-input__container.success .d-input__field {
  border-color: rgba(83, 183, 105, 0.3);
}
.d-input__submit {
  padding-top: 16px;
}
.d-input__submit .bt,
.d-input__submit .news-section__slider-controller .next,
.d-input__submit .news-section__slider-controller .prev,
.news-section__slider-controller .d-input__submit .next,
.news-section__slider-controller .d-input__submit .prev {
  width: 100%;
}
.d-input__footer {
  margin-top: 24px;
}
.d-input__footer p {
  margin: 0;
  color: #b0abbb;
}
.d-input__footer p a {
  color: #e7e5ec;
}
.navigation {
  border-bottom: 1px solid #1b1c2d;
  padding: 16px 0;
  position: fixed;
  z-index: 10000;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 1, 13, 0.84);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
}
@media only screen and (max-width: 1200px) {
  .navigation {
    padding: 12px 0;
  }
}
.navigation .hamburger {
  position: relative;
  margin-right: 16px;
  display: none;
}
@media only screen and (max-width: 1200px) {
  .navigation .hamburger {
    display: -ms-flexbox;
    display: flex;
    z-index: 10;
  }
}
.navigation nav {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
}
.navigation .logo {
  display: inline-block;
  padding: 2px 0;
}
.navigation .logo img {
  height: 36px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}
.navigation__left,
.navigation__left ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.navigation__left ul {
  list-style-type: none;
  margin: 0;
  padding-left: 44px;
}
@media only screen and (max-width: 1200px) {
  .navigation__left ul {
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: auto;
    max-width: 1000px;
  }
}
.navigation__left ul li {
  padding: 0 10px;
}
@media only screen and (max-width: 1200px) {
  .navigation__left ul li {
    width: 100%;
  }
}
.navigation__left ul li a {
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #8b8793;
  text-decoration: none;
  padding: 0 12px;
  transition: 0.2s ease;
}
.navigation__left ul li a svg {
  margin-right: 16px;
  display: none;
}
@media only screen and (max-width: 1200px) {
  .navigation__left ul li a svg {
    display: block;
  }
}
@media only screen and (max-width: 1200px) {
  .navigation__left ul li a {
    font-size: 1rem;
    font-size: 1.125rem;
    padding-top: 12px;
    padding-bottom: 12px;
    display: -ms-flexbox;
    display: flex;
  }
}
.navigation__left ul li a.active,
.navigation__left ul li a:hover {
  color: #fff;
}
.navigation__left-socials {
  padding: 0 12px;
}
.navigation__left-socials,
.navigation__left-socials a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.navigation__left-socials a {
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  text-decoration: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  transition: 0.2s ease;
  margin: 0 8px;
}
@media only screen and (max-width: 1200px) {
  .navigation__left-socials a {
    width: 48px;
    height: 48px;
  }
}
.navigation__left-socials a svg {
  transition: 0.2s ease;
}
.navigation__left-socials a:hover {
  background-color: #e7e5ec;
}
.navigation__left-socials a:hover svg {
  transform: scale(1.2);
}
.navigation__left-footer {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  grid-gap: 16px;
  display: none;
  background-color: #0e0f1a;
  border-top: 1px solid #1b1c2d;
  padding: 16px 24px;
  position: relative;
  z-index: inherit;
  margin-top: 24px;
}
.navigation__left-footer a {
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .navigation__left {
    position: fixed;
    height: 100vh;
    background-color: #00010d;
    top: 0;
    left: 0;
    z-index: 2;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 64px;
    transform: translateX(-100%);
    transition: 0.2s ease;
    visibility: hidden;
  }
  .navigation__left.active {
    visibility: visible;
    transform: translateX(0);
  }
  .navigation__left-footer {
    display: -ms-flexbox;
    display: flex;
  }
}
.navigation__right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 16px;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: auto;
}
.footer {
  padding-bottom: 32px;
}
.footer__logo {
  text-align: center;
}
.footer__logo a {
  display: inline-block;
}
.footer__logo a img {
  height: 36px;
}
.footer__socials {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 16px;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 24px 0;
}
@media only screen and (max-width: 768px) {
  .footer__socials {
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.footer__socials a {
  color: #0e0f1a;
  background-color: #242638;
  text-decoration: none;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 6px;
  transition: 0.2s ease;
}
.footer__socials a:hover {
  color: #fff;
}
.footer__list ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-gap: 12px;
}
.footer__list a {
  display: inline-block;
  text-decoration: none;
  color: #8b8793;
  padding: 8px 4px;
}
.footer__list a:hover {
  color: #e7e5ec;
}
.footer__list p {
  margin-bottom: 0;
  color: #8b8793;
  padding: 8px 0;
}
.footer__list ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .footer__list ul {
    text-align: center;
  }
}
.footer__list h6 {
  margin-top: 16px;
  margin-bottom: 32px;
}
:root {
  color-scheme: dark;
}
body {
  background-position: 50%;
  background-size: 1090px;
  background-image: url(../img/bg-stars.png);
}
.loading,
body {
  background-color: #00010d;
}
.loading {
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 100000;
  overflow: hidden;
}
.loading .load {
  position: absolute;
  width: 640px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
.loading .load div {
  position: absolute;
  width: 40px;
  height: 40px;
  opacity: 0;
  font-family: Helvetica, Arial, sans-serif;
  animation: b 2s linear infinite;
  -o-animation: b 2s linear infinite;
  -moz-animation: b 2s linear infinite;
  -webkit-animation: b 2s linear infinite;
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.loading .load div img {
  width: 100%;
  height: 100%;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: contain;
  object-fit: contain;
}
.loading .load div:nth-child(2) {
  animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
.loading .load div:nth-child(3) {
  animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}
.loading .load div:nth-child(4) {
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
.loading .load div:nth-child(5) {
  animation-delay: 0.8s;
  -o-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}
@keyframes b {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    left: 41%;
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    left: 59%;
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    left: 100%;
    transform: rotate(-180deg);
    opacity: 0;
  }
}
.main {
  padding-top: 73px;
  min-height: 60vh;
}
@media only screen and (max-width: 1200px) {
  .main {
    padding-top: 65px;
  }
}
.hidden {
  visibility: hidden;
  display: none;
}
.planet-graphics {
  height: 420px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  z-index: -1;
  margin-top: -82px;
  margin-bottom: -82px;
  -webkit-mask-image: radial-gradient(
    circle at center center,
    #00010d,
    transparent 80%
  );
}
@media only screen and (max-width: 768px) {
  .planet-graphics {
    margin-top: -128px;
    margin-bottom: -128px;
  }
}
.planet-graphics img {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}
.planet-graphics.mt--300 {
  margin-top: -300px;
}
.planet-graphics.mb--80 {
  margin-bottom: -80px;
}
@media only screen and (max-width: 991px) {
  .planet-graphics.mb--80 {
    margin-bottom: -130px;
  }
}
.planet-graphics:before {
  content: "";
  position: absolute;
  inset: 0;
}
.planet-graphics:after {
  content: "";
  position: absolute;
  background-color: #00010d;
  width: 200%;
  left: -50%;
  aspect-ratio: 1/0.7;
  border-radius: 50%;
  top: 50%;
}
.planet-graphics.blue:before {
  background: radial-gradient(
    circle at bottom center,
    #191e63,
    transparent 60%
  );
}
.planet-graphics.blue:after {
  border-top: 1px solid #5d6085;
}
.planet-graphics.yellow:before {
  background: radial-gradient(
    circle at bottom center,
    #3f2710,
    transparent 56%
  );
}
.planet-graphics.yellow:after {
  border-top: 1px solid #dd700c;
}
.planet-graphics.red:before {
  background: radial-gradient(
    circle at bottom center,
    #8d1f2c,
    transparent 56%
  );
}
.planet-graphics.red:after {
  border-top: 1px solid #8c5a66;
}
.planet-graphics.green:before {
  background: radial-gradient(
    circle at bottom center,
    #316837,
    transparent 52%
  );
}
.planet-graphics.green:after {
  border-top: 1px solid #5a8c62;
}
.planet-graphics.down {
  transform: rotate(180deg);
}
.planet-graphics.down.yellow:before {
  background: radial-gradient(
    circle at bottom center,
    #3f2710,
    transparent 40%
  );
}
@keyframes c {
  0% {
    background-position-x: 0;
  }
  to {
    background-position-x: -1920px;
  }
}
.headline {
  position: relative;
  z-index: 2;
  width: 100%;
}
.headline:not(.headline-main) h1,
.headline:not(.headline-main) h2,
.headline:not(.headline-main) h3,
.headline:not(.headline-main) h4,
.headline:not(.headline-main) h5,
.headline:not(.headline-main) h6 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.headline:not(.headline-main) .headline__cta {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (max-width: 576px) {
  .headline__cta a {
    width: 100%;
  }
}
.headline__cta.center a {
  margin: 0 auto;
}
@media only screen and (max-width: 576px) {
  .headline__cta.center a {
    width: auto;
  }
}
.headline p {
  color: #b0abbb;
  margin-bottom: 0;
}
.headline h1.yellow-green,
.headline h2.yellow-green,
.headline h3.yellow-green,
.headline h4.yellow-green,
.headline h5.yellow-green,
.headline h6.yellow-green {
  background: linear-gradient(90deg, #e8e13e, #46e369);
}
.headline h1.purple-green,
.headline h2.purple-green,
.headline h3.purple-green,
.headline h4.purple-green,
.headline h5.purple-green,
.headline h6.purple-green {
  background: linear-gradient(90deg, #a67dfa, #b8f393);
}
.headline h1.yellow-pink,
.headline h2.yellow-pink,
.headline h3.yellow-pink,
.headline h4.yellow-pink,
.headline h5.yellow-pink,
.headline h6.yellow-pink {
  background: linear-gradient(90deg, #f7be2b, #ee2c72);
}
.headline h1.grey-grey,
.headline h2.grey-grey,
.headline h3.grey-grey,
.headline h4.grey-grey,
.headline h5.grey-grey,
.headline h6.grey-grey {
  background: linear-gradient(90deg, #707acf, #9fabc3);
}
.headline h1.green-blue,
.headline h2.green-blue,
.headline h3.green-blue,
.headline h4.green-blue,
.headline h5.green-blue,
.headline h6.green-blue {
  background: linear-gradient(90deg, #25cf69, #3b86f6);
}
.headline h1.blue-purple,
.headline h2.blue-purple,
.headline h3.blue-purple,
.headline h4.blue-purple,
.headline h5.blue-purple,
.headline h6.blue-purple {
  background: linear-gradient(90deg, #98bdf3, #5a21f9);
}
.headline p.subtitle {
  display: inline-block;
  padding: 4px 16px;
  border-radius: 24px;
  margin-bottom: 16px;
}
.headline p.subtitle span {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.headline p.subtitle.yellow-green {
  background: linear-gradient(
    90deg,
    rgba(232, 225, 62, 0),
    rgba(70, 227, 105, 0.24)
  );
}
.headline p.subtitle.yellow-green span {
  background: linear-gradient(90deg, #e8e13e, #46e369);
}
.headline p.subtitle.purple-green {
  background: linear-gradient(
    90deg,
    rgba(166, 125, 250, 0),
    hsla(97, 80%, 76%, 0.24)
  );
}
.headline p.subtitle.purple-green span {
  background: linear-gradient(90deg, #a67dfa, #b8f393);
}
.headline p.subtitle.yellow-pink {
  background: linear-gradient(
    90deg,
    rgba(247, 190, 43, 0),
    rgba(238, 44, 114, 0.24)
  );
}
.headline p.subtitle.yellow-pink span {
  background: linear-gradient(90deg, #f7be2b, #ee2c72);
}
.headline p.subtitle.grey-grey {
  background: linear-gradient(
    90deg,
    rgba(112, 122, 207, 0),
    rgba(159, 171, 195, 0.24)
  );
}
.headline p.subtitle.grey-grey span {
  background: linear-gradient(90deg, #707acf, #9fabc3);
}
.headline p.subtitle.green-blue {
  background: linear-gradient(
    90deg,
    rgba(37, 207, 105, 0),
    rgba(59, 134, 246, 0.24)
  );
}
.headline p.subtitle.green-blue span {
  background: linear-gradient(90deg, #25cf69, #3b86f6);
}
.headline p.subtitle.blue-purple {
  background: linear-gradient(
    90deg,
    rgba(152, 189, 243, 0),
    rgba(90, 33, 249, 0.24)
  );
}
.headline p.subtitle.blue-purple span {
  background: linear-gradient(90deg, #98bdf3, #5a21f9);
}
.headline-main p {
  color: #e7e5ec;
  margin-bottom: 0;
}
.headline__cta {
  margin-top: 40px;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 24px;
}
.headline.max-900 {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.page-pagination {
  padding-bottom: 84px;
}
.page-pagination ul {
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0;
  grid-gap: 12px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.news-section__slider-controller .page-pagination ul .next,
.news-section__slider-controller .page-pagination ul .prev,
.page-pagination ul .bt-secondary,
.page-pagination ul .news-section__slider-controller .next,
.page-pagination ul .news-section__slider-controller .prev {
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: #1b1c2d;
}
.news-section__slider-controller .page-pagination ul .next:after,
.news-section__slider-controller .page-pagination ul .prev:after,
.page-pagination ul .bt-secondary:after,
.page-pagination ul .news-section__slider-controller .next:after,
.page-pagination ul .news-section__slider-controller .prev:after {
  display: none;
}
.news-section__slider-controller .page-pagination ul .active.next,
.news-section__slider-controller .page-pagination ul .active.prev,
.page-pagination ul .bt-secondary.active,
.page-pagination ul .news-section__slider-controller .active.next,
.page-pagination ul .news-section__slider-controller .active.prev {
  background-color: #110f24;
}
.news-section__slider-controller .page-pagination ul .active.next:after,
.news-section__slider-controller .page-pagination ul .active.prev:after,
.page-pagination ul .bt-secondary.active:after,
.page-pagination ul .news-section__slider-controller .active.next:after,
.page-pagination ul .news-section__slider-controller .active.prev:after {
  display: block;
}
.gradient-card,
.waitlist-modal {
  border-radius: 32px;
  border: 1px solid #1b1c2d;
  padding: 64px;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .gradient-card,
  .waitlist-modal {
    margin-bottom: 24px;
    padding: 48px 24px;
  }
}
.gradient-card.gradient-left,
.gradient-left.waitlist-modal {
  background: linear-gradient(90deg, #0e0f1a, rgba(14, 15, 26, 0));
}
.gradient-card.gradient-right,
.gradient-right.waitlist-modal {
  background: linear-gradient(270deg, #0e0f1a, rgba(14, 15, 26, 0));
}
.gradient-card.gradient-none,
.gradient-none.waitlist-modal {
  background: #00010d;
}
.gradient-card.gradient-light,
.waitlist-modal {
  background: #0e0f1a;
}
.gem-path.waitlist-modal:before,
.gradient-card.gem-path:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 2;
}
@media only screen and (max-width: 991px) {
  .gem-path.waitlist-modal:before,
  .gradient-card.gem-path:before {
    top: -88px;
    transform: rotate(90deg) translateX(-50%);
    left: 50%;
    transform-origin: left;
  }
}
.gradient-card.red:before,
.red.waitlist-modal:before {
  width: 104px;
  height: 146px;
  background-image: url(../img/paths/red-path.svg);
  right: -63px;
}
.gradient-card.purple:before,
.purple.waitlist-modal:before {
  width: 104px;
  height: 146px;
  background-image: url(../img/paths/purple-path.svg);
  right: -63px;
}
.blue.waitlist-modal:before,
.gradient-card.blue:before {
  width: 104px;
  height: 146px;
  background-image: url(../img/paths/blue-path.svg);
  right: -65px;
}
@media only screen and (max-width: 991px) {
  .blue.waitlist-modal:before,
  .gradient-card.blue:before {
    top: -86px;
  }
}
.gradient-card.graphic-path:after,
.graphic-path.waitlist-modal:after {
  content: "";
  position: absolute;
  top: -140px;
  right: -140px;
  width: 440px;
  height: 440px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../img/about-us-graphic.png);
}
@media only screen and (max-width: 768px) {
  .gradient-card.graphic-path:after,
  .graphic-path.waitlist-modal:after {
    top: 0;
    right: 0;
    width: 140px;
    height: 140px;
  }
}
.gradient-card img,
.waitlist-modal img {
  max-width: 100%;
}
.top-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 68vh;
  padding: 110px 0;
}
.top-section .cover {
  position: absolute;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}
.top-section .cover:after,
.top-section .cover:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
}
.top-section .cover:before {
  top: 0;
  height: 100%;
  background-color: rgba(0, 1, 13, 0.8);
}
.top-section .cover:after {
  bottom: 0;
  height: 80px;
  background-image: linear-gradient(0deg, #00010d 50%, rgba(0, 1, 13, 0));
}
.top-section .cover__row {
  display: -ms-flexbox;
  display: flex;
  animation: c 20s linear infinite;
  width: 100%;
  height: 20%;
  background-size: cover;
}
.top-section .cover__row:after,
.top-section .cover__row:before {
  content: "";
  height: 100%;
  position: absolute;
  width: 16%;
}
.top-section .cover__row:before {
  left: 0;
  background-image: linear-gradient(90deg, #0e0f1a, rgba(14, 15, 26, 0));
}
.top-section .cover__row:after {
  right: 0;
  background-image: linear-gradient(270deg, #0e0f1a, rgba(14, 15, 26, 0));
}
.top-section .cover__row.img-1 {
  background-image: url(../img/cover-1.webp);
  animation: c 32s linear infinite;
}
.top-section .cover__row.img-2 {
  background-image: url(../img/cover-2.webp);
  animation: c 38s linear infinite;
}
.top-section .cover__row.img-3 {
  background-image: url(../img/cover-3.webp);
  animation: c 54s linear infinite;
}
.top-section .cover__row.img-4 {
  background-image: url(../img/cover-4.webp);
  animation: c 44s linear infinite;
}
.top-section .cover__row.img-5 {
  background-image: url(../img/cover-5.webp);
  animation: c 28s linear infinite;
}
.top-section .headline-main {
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .top-section .headline__cta {
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 400px;
    margin: 32px auto 0;
  }
  .news-section__slider-controller .top-section .headline__cta .next,
  .news-section__slider-controller .top-section .headline__cta .prev,
  .top-section .headline__cta .bt,
  .top-section .headline__cta .news-section__slider-controller .next,
  .top-section .headline__cta .news-section__slider-controller .prev {
    width: 100%;
  }
}
.activity-section {
  text-align: center;
  padding: 34px 0;
}
@media only screen and (max-width: 768px) {
  .activity-section {
    padding-top: 0;
    padding-bottom: 46px;
  }
}
.activity-section h2 {
  color: unset;
  background: linear-gradient(90deg, #707acf, #9fabc3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}
.activity-section p {
  margin: 0;
  color: #b0abbb;
}
.activity-section__item {
  padding: 36px 0;
}
@media only screen and (max-width: 768px) {
  .activity-section__item {
    padding: 24px 0;
  }
}
.about-section {
  overflow: hidden;
  padding-top: 128px;
  padding-bottom: 46px;
}
@media only screen and (max-width: 991px) {
  .about-section {
    padding-top: 46px;
    padding-bottom: 0;
  }
}
.gameplay-section {
  overflow: hidden;
  margin-top: -128px;
  margin-bottom: -128px;
}
.gameplay-section .headline {
  position: relative;
  padding: 128px 0;
}
@media only screen and (max-width: 768px) {
  .gameplay-section .headline {
    padding: 24px 0;
  }
}
/* .gameplay-section .headline:after,
.gameplay-section .headline:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
} */
@media only screen and (max-width: 768px) {
  .gameplay-section .headline:after,
  .gameplay-section .headline:before {
    display: none;
  }
}
/* .gameplay-section .headline:before {
  left: calc(-100% - 12px);
  background-position-x: right;
  background-image: url(../img/gameplay-left.png);
}
.gameplay-section .headline:after {
  right: calc(-100% - 12px);
  background-position-x: left;
  background-image: url(../img/gameplay-right.png);
} */
.gameplay-section__img {
  width: 100%;
  display: none;
}
@media only screen and (max-width: 768px) {
  .gameplay-section__img {
    display: block;
  }
}
.gameplay-section__img img {
  width: 100%;
}
.rewards-section .headline p.subtitle span {
  -webkit-background-clip: unset !important;
  -webkit-text-fill-color: unset !important;
  color: #e7e5ec;
  background-image: none;
}
.rewards-section ul {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.rewards-section ul .icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #707acf, #9fabc3);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 16px;
  margin-top: 2px;
}
.rewards-section ul .icon svg {
  color: #00010d;
}
.rewards-section ul li {
  display: -ms-flexbox;
  display: flex;
  padding: 6px 0;
}
@media only screen and (max-width: 991px) {
  .rewards-section .gradient-card,
  .rewards-section .waitlist-modal {
    height: auto;
  }
  .rewards-section .gradient-card:before,
  .rewards-section .waitlist-modal:before {
    top: unset;
    bottom: -84px;
  }
  .rewards-section .gradient-card.gradient-left,
  .rewards-section .gradient-left.waitlist-modal {
    margin-bottom: 0;
  }
}
.roadmap-section {
  padding-top: 128px;
}
@media only screen and (max-width: 991px) {
  .roadmap-section {
    padding-top: 84px;
  }
}
@media only screen and (max-width: 576px) {
  .roadmap-section {
    padding-top: 64px;
  }
}
.custom-row {
  display: -ms-flexbox;
  display: flex;
  padding: 46px 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}
@media only screen and (max-width: 1200px) {
  .custom-row {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.custom-row .custom-column {
  width: 20%;
  padding: 0 12px;
}
@media only screen and (max-width: 1200px) {
  .custom-row .custom-column {
    width: 50%;
  }
}
@media only screen and (max-width: 576px) {
  .custom-row .custom-column {
    width: 100%;
  }
}
.d-card {
  background-color: #0e0f1a;
  padding: 16px;
  border-radius: 32px;
  position: relative;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.d-card.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 16px;
  padding: 24px 16px;
}
.d-card.flex-row p {
  margin-bottom: 0;
}
.d-card:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background-image: linear-gradient(135deg, #016eda, #d51375, #d51375, #016eda);
  background-size: 300% 100%;
  border-radius: 32px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.2s ease;
}
.d-card:hover:after {
  opacity: 1;
}
.d-card:hover .icon {
  transform: scale(1.2);
}
.d-card .icon {
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  transition: 0.3s;
}
.d-card .icon img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}
.d-card__text h5 {
  background: linear-gradient(90deg, #f7be2b, #ee2c72);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.d-card__text p {
  margin-bottom: 8px;
  color: #b0abbb;
}
@media only screen and (max-width: 1200px) {
  .d-card {
    margin-bottom: 24px;
  }
}
.news-section__container {
  padding: 24px 0 46px;
  position: relative;
}
.news-section__slider {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: end;
  align-items: flex-end;
  grid-gap: 24px;
}
.news-section__slider-controller {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 16px;
}
.news-section__slider-controller .next,
.news-section__slider-controller .prev {
  width: 40px;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.news-section__slider-controller .next:after,
.news-section__slider-controller .prev:after {
  display: none;
}
.news-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 392px;
  margin-bottom: 24px;
  overflow: hidden;
  transition: 0.2s ease;
  text-decoration: none;
}
@media only screen and (max-width: 991px) {
  .news-card {
    height: 342px;
  }
}
.news-card:hover .news-card__info h6 {
  color: #f02b5a;
}
.news-card:hover .news-card__img img {
  transform: scale(1.2);
}
.news-card .date {
  display: inline-block;
  padding: 1px 12px;
  border-radius: 24px;
  margin-bottom: 16px;
  background: linear-gradient(
    90deg,
    rgba(37, 207, 105, 0),
    rgba(59, 134, 246, 0.24)
  );
  margin-bottom: 12px;
}
.news-card .date span {
  background: linear-gradient(90deg, #25cf69, #3b86f6);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.news-card__img {
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  height: 200px;
}
.news-card__img .info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 0;
  background-image: linear-gradient(0deg, #00010d 50%, rgba(0, 1, 13, 0));
}
.news-card__img .info h3 {
  background: linear-gradient(90deg, #f7be2b, #ee2c72);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.news-card__img img {
  width: 100%;
  height: 100%;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  transition: transform 0.2s ease;
}
.news-card__info {
  padding: 16px 0;
}
.news-card__info h6 {
  transition: color 0.2s ease;
  color: #fff;
}
.news-card__info p {
  margin: 0;
  color: #b0abbb;
}
.news-card__info p span {
  text-decoration: none;
  color: #fff;
}
.cta-section {
  padding-top: 128px;
  padding-bottom: 64px;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .cta-section {
    padding-top: 84px;
    padding-bottom: 0;
  }
}
.cta-section__body {
  border-radius: 32px;
  position: relative;
  background-image: linear-gradient(135deg, #d51375, #016eda, #016eda, #d51375);
  padding: 64px;
  background-size: 300% 100%;
  background-position: 100% 0;
}
@media only screen and (max-width: 576px) {
  .cta-section__body {
    padding: 32px 24px 12px 32px;
  }
}
.cta-section__body:before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 32px;
  background-image: linear-gradient(135deg, #016eda, #d51375, #d51375, #016eda);
  background-size: 300% 100%;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .cta-section__body .headline {
    text-align: center;
  }
}
.cta-section__body .headline h2 {
  -webkit-background-clip: unset !important;
  -webkit-text-fill-color: unset !important;
  color: #e7e5ec;
  background-image: none;
}
.cta-section__body .headline__cta a {
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .cta-section__body .headline__cta {
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 768px) {
  .cta-section__body .headline__cta {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    grid-gap: 12px;
  }
  .cta-section__body .headline__cta a,
  .cta-section__body .headline__cta a img {
    width: 100%;
  }
}
.cta-section__img {
  position: absolute;
  width: 630px;
  top: 50%;
  right: -6%;
  transform: translateY(-50%);
}
@media only screen and (max-width: 1200px) {
  .cta-section__img {
    width: 430px;
  }
}
@media only screen and (max-width: 991px) {
  .cta-section__img {
    position: static;
    transform: none;
    padding-top: 32px;
    margin: 0 auto;
    right: 0;
  }
}
@media only screen and (max-width: 576px) {
  .cta-section__img {
    width: 100%;
  }
}
.cta-section__img img {
  width: 100%;
}
.faq-section {
  padding-bottom: 84px;
}
@media only screen and (max-width: 991px) {
  .faq-section {
    padding-bottom: 64px;
  }
}
.faq-section__container {
  padding: 46px 0;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.faq-section__item .question {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #e7e5ec;
  padding: 24px 16px;
  border-bottom: 1px solid #242638;
  cursor: pointer;
  transition: 0.2s ease;
}
.faq-section__item .question > * {
  margin: 0;
}
.faq-section__item .question-cls {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  margin-left: 12px;
}
.faq-section__item .question-cls:after,
.faq-section__item .question-cls:before {
  content: "";
  width: 16px;
  height: 2px;
  background-color: #b0abbb;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 2px;
}
.faq-section__item .question-cls:before {
  transform: translate(-50%, -50%) rotate(90deg);
}
.faq-section__item .question-cls:after {
  transform: translate(-50%, -50%);
}
.faq-section__item .question:hover > * {
  color: #fff;
}
.faq-section__item .question:hover .question-cls:after,
.faq-section__item .question:hover .question-cls:before {
  background-color: #f02b5a;
}
.faq-section__item .answer {
  padding: 24px 16px;
  border-radius: 12px;
  background-image: linear-gradient(90deg, #0e0f1a, rgba(14, 15, 26, 0));
  color: #b0abbb;
  border: 1px solid #242638;
}
.faq-section__item .answer > * {
  margin: 0;
}
.faq-section__item-cta {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 12px;
  padding-top: 12px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.faq-section__item-cta a {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #242638;
  border: 1px solid #242638;
  border-radius: 24px;
  color: #fff;
  text-decoration: none;
  padding: 4px 16px;
  transition: 0.2s ease;
}
.faq-section__item-cta a svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-left: -6px;
}
.faq-section__item-cta a:hover {
  background-color: #00010d;
}
.faq-section__item.active,
.faq-section__item.active .question {
  border: none;
}
.faq-section__item.active .question-cls:before {
  transform: translate(-50%, -50%) rotate(0deg);
}
.faq-section__footer {
  text-align: center;
}
.faq-section__footer p {
  margin: 0;
  color: #8b8793;
}
.faq-section__footer p span {
  color: #f02b5a;
}
.headline-section {
  padding-top: 64px;
}
.headline-section .planet-graphics {
  margin-top: -156px;
}
.headline-section h1 p {
  color: #e7e5ec;
}
.section-graphic {
  padding: 24px 0;
}
.section-graphic img {
  width: 100%;
  height: 100%;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: contain;
  object-fit: contain;
}
.about-us-section {
  padding-bottom: 64px;
}
@media only screen and (max-width: 991px) {
  .about-us-section {
    padding-bottom: 46px;
  }
}
.about-us-section .section-graphic img {
  max-height: 520px;
}
.vision-section {
  padding-bottom: 64px;
}
@media only screen and (max-width: 991px) {
  .vision-section {
    padding-top: 0;
    padding-bottom: 46px;
  }
}
.vision-section .d-card.mt-46 {
  margin-top: 46px;
}
@media only screen and (max-width: 991px) {
  .vision-section .d-card.mt-46 {
    margin-top: 0;
  }
}
.vision-section .d-card.mt-92 {
  margin-top: 92px;
}
@media only screen and (max-width: 991px) {
  .vision-section .d-card.mt-92 {
    margin-top: 0;
  }
}
.vision-section .d-card__text h5 {
  background: linear-gradient(90deg, #e8e13e, #46e369);
}
.benefits-section {
  padding-top: 64px;
}
.benefits-section .headline p {
  color: #e7e5ec;
}
.benefits-section__list {
  padding-top: 25px;
}
.benefits-section__list-item {
  padding: 23px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.benefits-section__list-item .img {
  width: 60px;
  height: 60px;
  margin-right: 24px;
}
.benefits-section__list-item .img img {
  width: 100%;
  height: 100%;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: contain;
  object-fit: contain;
}
.benefits-section__list-item .text h4 {
  background: linear-gradient(90deg, #25cf69, #3b86f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.benefits-section__list-item .text p {
  margin: 0;
  color: #b0abbb;
}
.team-section {
  padding-bottom: 128px;
}
@media only screen and (max-width: 768px) {
  .team-section {
    padding-bottom: 46px;
  }
}
.team-section .headline {
  margin-bottom: 46px;
}
.team-section .team-member {
  position: relative;
  border-radius: 50%;
  margin-bottom: 24px;
}
.team-section .team-member:after {
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: 300% 100%;
  background: linear-gradient(135deg, #016eda, #d51375, #d51375, #016eda);
  z-index: -1;
  border-radius: 50%;
  transition: 0.2s ease;
  opacity: 0;
  visibility: hidden;
}
.team-section .team-member__img {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.team-section .team-member__img img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.team-section .team-member__img img,
.team-section .team-member__info {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.team-section .team-member__info {
  background-image: linear-gradient(0deg, #00010d 50%, rgba(0, 1, 13, 0));
  z-index: 2;
  border-radius: 50%;
  text-align: center;
  padding: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  transition: 0.2s ease;
  opacity: 0;
  visibility: hidden;
}
.team-section .team-member__info h5,
.team-section .team-member__info p {
  transition: 0.2s ease;
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
}
.team-section .team-member__info h5 {
  margin: 0;
}
.team-section .team-member__info p {
  color: #b0abbb;
  margin: 0;
}
.team-section .team-member:hover .team-member__info,
.team-section .team-member:hover .team-member__info h5,
.team-section .team-member:hover .team-member__info p,
.team-section .team-member:hover:after {
  opacity: 1;
  visibility: visible;
}
.team-section .team-member:hover .team-member__info h5,
.team-section .team-member:hover .team-member__info p {
  transform: translateY(0);
}
.contact-section {
  padding-bottom: 84px;
}
.contact-section .gradient-card,
.contact-section .waitlist-modal {
  -ms-flex-align: start;
  align-items: flex-start;
}
.contact-section .contact-details .headline {
  margin-bottom: 36px;
}
.contact-section .contact-details .headline h3 {
  -webkit-background-clip: unset !important;
  -webkit-text-fill-color: unset !important;
  color: #242638;
  background-image: none;
}
.contact-section .contact-details__item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
}
.contact-section .contact-details__item .icon {
  width: 48px;
  height: 48px;
  background-color: rgba(240, 43, 90, 0.24);
  border-radius: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 12px;
}
.contact-section .contact-details__item .icon svg {
  color: #f02b5a;
  width: 24px;
  height: 24px;
}
.contact-section .contact-details__item .info h6 {
  color: #f02b5a;
  margin: 0;
}
.contact-section .contact-details__item .info p {
  margin: 0;
}
.contact-section__sent {
  display: none;
}
.contact-section__sent-additional {
  margin-top: 24px;
  padding: 32px 24px;
  border-radius: 16px;
  background-color: #00010d;
  border: 1px solid #1b1c2d;
}
.contact-section__sent-additional p {
  color: #e7e5ec;
}
.contact-section__sent-additional ul {
  list-style-type: "\2014";
  padding-left: 16px;
  margin-top: 24px;
  margin-bottom: 0;
}
.contact-section__sent-additional ul li {
  padding-left: 6px;
  margin: 8px 0;
}
.contact-section__sent-additional ul li a {
  color: #b0abbb;
  transition: 0.2s ease;
}
.contact-section__sent-additional ul li a:hover {
  color: #e7e5ec;
  text-decoration: none;
}
.whitepaper-section {
  padding-bottom: 128px;
}
.whitepaper-section .whitepaper__sidebar {
  position: sticky;
  top: 89px;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 24px;
  border: 1px solid #1b1c2d;
}
.news-section__slider-controller .whitepaper-section .whitepaper__sidebar .next,
.news-section__slider-controller .whitepaper-section .whitepaper__sidebar .prev,
.whitepaper-section .whitepaper__sidebar .bt,
.whitepaper-section .whitepaper__sidebar .news-section__slider-controller .next,
.whitepaper-section
  .whitepaper__sidebar
  .news-section__slider-controller
  .prev {
  width: 100%;
}
.whitepaper-section .whitepaper__sidebar ul {
  padding: 0;
  margin-bottom: 32px;
  list-style-type: none;
}
.whitepaper-section .whitepaper__sidebar ul a {
  color: #b0abbb;
  text-decoration: none;
  transition: 0.2s ease;
  padding: 8px 0;
  display: block;
}
.whitepaper-section .whitepaper__sidebar ul a.active,
.whitepaper-section .whitepaper__sidebar ul a:hover {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .whitepaper-section .whitepaper__sidebar {
    border: none;
    text-align: center;
    padding: 0;
  }
  .news-section__slider-controller
    .whitepaper-section
    .whitepaper__sidebar
    .next,
  .news-section__slider-controller
    .whitepaper-section
    .whitepaper__sidebar
    .prev,
  .whitepaper-section .whitepaper__sidebar .bt,
  .whitepaper-section
    .whitepaper__sidebar
    .news-section__slider-controller
    .next,
  .whitepaper-section
    .whitepaper__sidebar
    .news-section__slider-controller
    .prev {
    width: auto;
  }
  .whitepaper-section .whitepaper__sidebar ul {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    overflow-x: auto;
  }
  .whitepaper-section .whitepaper__sidebar ul a {
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
  }
  .whitepaper-section .whitepaper__sidebar ul a.active,
  .whitepaper-section .whitepaper__sidebar ul a:hover {
    background-color: #0e0f1a;
    border-radius: 12px;
  }
}
.whitepaper-section .whitepaper__body ol,
.whitepaper-section .whitepaper__body p,
.whitepaper-section .whitepaper__body ul {
  color: #e7e5ec;
}
.waitlist-section {
  min-height: 72vh;
}
.waitlist-section__body {
  padding: 84px 0;
  max-width: 900px;
  margin: 0 auto;
}
.waitlist-section__headline {
  margin-bottom: 46px;
  text-align: center;
}
.waitlist-section__headline p {
  color: #e7e5ec;
}
.waitlist-section .waitlist-section__form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.waitlist-modal {
  height: auto;
  padding: 36px 24px 24px;
  max-width: 500px;
  margin: 0 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  display: none;
}
.waitlist-modal img {
  margin-bottom: 24px;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.waitlist-modal p {
  color: #b0abbb;
}
.waitlist-modal__footer {
  margin-top: 24px;
  padding: 16px 12px;
  background-color: #1b1c2d;
  width: 100%;
  border-radius: 16px;
  color: #b0abbb;
}
.waitlist-cta-section {
  padding: 64px 0;
}
.news-section__slider-controller .waitlist-cta-section .next,
.news-section__slider-controller .waitlist-cta-section .prev,
.waitlist-cta-section .bt,
.waitlist-cta-section .news-section__slider-controller .next,
.waitlist-cta-section .news-section__slider-controller .prev {
  margin-top: 36px;
}
.page-404 {
  height: 100vh;
  background-image: url(../img/404bg.png);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
.page-404 p {
  color: #242638;
  font-family: latoSemiBold;
}
.page-404 h1,
.page-404 p {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.page-404 h1 {
  font-size: calc(12rem + 3.3vw);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}
.page-404 h1 span {
  font-family: poppinsBold;
  position: relative;
  z-index: -2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: linear-gradient(90deg, #707acf, #9fabc3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page-404 h1 span:last-child {
  z-index: 1;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: unset;
  color: #9fabc3;
}
.page-404 h1 span:last-child:after {
  content: "";
  width: 580px;
  height: 440px;
  -webkit-user-drag: none;
  position: absolute;
  background-image: url(../img/404rocket.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: -1;
  top: 36%;
  left: -40%;
}
.page-404 h3 {
  margin-bottom: 46px;
  color: #e7e5ec;
  position: relative;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.terms-modal {
  position: fixed;
  z-index: 999999;
  left: 50%;
  top: 50%;
  tansform: translateX(-50%);
  transform: translate(-50%, -50%);
  height: calc(100vh - 48px);
  width: calc(100% - 24px);
  max-width: 1304px;
  background-color: #00010d;
  border-radius: 32px;
  border: 1px solid #1b1c2d;
  padding-top: 56px;
}
.terms-modal__cls {
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.2s ease;
  color: #b0abbb;
}
.terms-modal__cls:hover {
  color: #fff;
}
.terms-modal__body {
  padding: 24px;
  height: 100%;
  overflow-y: auto;
}
.cookies {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;
  width: calc(100% - 24px);
  max-width: 1308px;
  padding: 24px;
  background-image: linear-gradient(90deg, #0e0f1a, #00010d);
  border: 1px solid #242638;
  z-index: 9999;
  border-radius: 24px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 24px;
}
@media only screen and (max-width: 576px) {
  .cookies {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.cookies__btn {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 12px;
  margin-left: auto;
}
@media only screen and (max-width: 576px) {
  .cookies__btn {
    margin: 0;
  }
}
.cookies__body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 16px;
}
.cookies__body-img {
  width: 56px;
  min-width: 56px;
  height: 56px;
}
.cookies__body-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}
.cookies__body p {
  margin: 0;
  color: #b0abbb;
}
.cookies__body p a {
  color: #fff;
  text-decoration: none;
}
.cookies__body p a:hover {
  text-decoration: underline;
}
.testDiv {
  min-height: 300px;
  margin-bottom: 10px;
  background-image: linear-gradient(90deg, #f7be2b, #ee2c72);
}
